@import '_variables';

#root {
    height: 100%;
}

#outer-container {
    min-height: 100%;
    background-color: $neutral-50;
    // overflow: hidden;
}

#page-wrap {
    position: relative;
    z-index: 0;
    contain: layout;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 1.5rem 1.75rem;
    height: 100%;
}

.safari-fix #page-wrap {
    position: fixed;
    overflow: hidden;
    -webkit-overflow-scrolling: auto;
}

.loading-container {
    margin-top: $spacing-double;
}

.no-transition,
.no-transition * {
    transition-property: none !important;
    transform: none !important;
    animation: none !important;
}

.top-spacing {
    margin-top: $spacing;
}

.top-spacing-double {
    margin-top: $spacing-double;
}

.top-spacing-third {
    margin-top: $spacing-third;
}

.top-spacing-half {
    margin-top: $spacing-half;
}

.flex-centered {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-spaced {
    display: flex;
    justify-content: space-between;
}

.modal-content {
    border: none;
}

.Toastify__toast {
    border-radius: $border-radius-large;
    color: $neutral-50 !important;
    margin-bottom: 1rem !important;

    @include screen-xs {
        margin: 1rem !important;
    }

    &--error {
        background-color: $color-danger !important;
    }

    &--success {
        background-color: $stone-500 !important;
    }

    &-body {
        div:last-child {
            font-size: 12px;
            line-height: 16px;
            font-family: $font-family-Mulish-regular;
        }
    }

    &-icon {
        width: auto;
    }
}

.radius {
    &-0 {
        border-radius: $border-radius-base;
    }

    &-4 {
        border-radius: $border-radius-small;
    }

    &-6 {
        border-radius: $border-radius-medium;
    }

    &-9 {
        border-radius: $border-radius-large;
    }

    &-10 {
        border-radius: $border-radius-xl;
    }

    &-12 {
        border-radius: $border-radius-xxl;
    }
}

.cursor-pointer:hover {
    cursor: pointer;
}

.img-gallery-modal {
    .image-gallery-content.fullscreen{
        .image-grid__gallery-item{
            img{
                width: 100%;
                max-height: 100%;
                height: calc(100vh - 100px);
                object-fit: contain;
            }
        }
    }
}

.img-gallery-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.__cbio_ignored.cbio_session_controls{
    transform: none !important;
    left: inherit !important;
    right: 20px;
}

.ra {
    .login,.registration-panel, .background-wrapper, .initial-route, .pinboard__header, .header-banner {
        background-color: rgba(0, 34, 84, .8) !important;
        background-blend-mode: color-dodge;
    }
    .btn{   
            &-secondary {
                    border-color: $red-500 !important;
                    background-color: $neutral-0 !important;
                    color: $red-500 !important;
            
                    &:hover {
                        border-color: $red-600 !important;
                        background-color: $red-50 !important;
                        color: $red-600 !important;
                    }
            
                    &:focus {
                        box-shadow: none !important;
                        outline: 3px solid $red-100 !important;
                        border-color: $red-100 !important;
                    }
            
                    &:disabled {
                        opacity: 1 !important;
                        border-color: $red-200 !important;
                        background-color: $neutral-0 !important;
                        color: $red-200 !important;
                    }
            }
            &-secondary-form {
                border-color: $neutral-100 !important;
                background-color: $neutral-50 !important;
                color: $blue-500 !important;
        
                &:hover {
                    border-color: $blue-600 !important;
                    background-color: $blue-50 !important;
                    color: $blue-600 !important;
                }
        
                &:focus {
                    box-shadow: none !important;
                    outline: 3px solid $blue-100 !important;
                    border-color: $blue-100 !important;
                }
        
                &:disabled {
                    opacity: 1 !important;
                    border-color: $blue-200 !important;
                    background-color: $neutral-0 !important;
                    color: $blue-200 !important;
                }
            }
            &-primary:not(.secondary-form) {
                border-color: $red-500 !important;
                    background-color: $red-500 !important;
                    color: $neutral-0 !important;
            
                    &:hover {
                        border-color: $red-600 !important;
                        background-color: $red-600 !important;
                    }
            
                    &:focus {
                        box-shadow: none !important;
                    }
            
                    &:disabled {
                        opacity: 1 !important;
                        border-color: $red-100 !important;
                        background-color: $red-100 !important;
                        color: $red-50 !important;
                    }
            }
        
            &-tertiary,
            &-link {
                    height: auto !important;
                    min-height: 0 !important;
            
                    border-color: transparent;
                    background-color: transparent;
                    color: $red-500 !important;
            
                    &:hover {
                        color: $red-600 !important;
                    }
            
                    &:focus {
                        box-shadow: none !important;
                    }
            
                    &:disabled {
                        opacity: 1 !important;
                        color: $red-300 !important;
                    }
            }

            &-icon-only{
                border-color: $red-500 !important;
                color: $red-500 !important;

                svg{
                    fill: $red-500 !important;
                }
            }

    }
    
    input.form-control{
            &:active:not(:disabled),
            &:focus:not(:disabled) {
                border-color: $blue-500 !important;
            }
    }
    textarea.form-control{
        &:active:not(:disabled),
        &:focus:not(:disabled) {
            border-color: $blue-500 !important;
        }
}

    .choice{
        .checked:not(.disabled){
            background-color: $red-500 !important;
            border-color: $red-500 !important;
        }

        .choice__wrapper.choice__radio.checked.disabled{
            background-color: $neutral-0 !important;
            border-color: $red-500 !important;

            .choice-checked{
                background-color: $red-500 !important;
            }
        }
    }

    .collapsible-sidebar__content-menu__active{
        .collapsible-sidebar__menu-label{
            color: $red-500 !important;
        }
    }

    .page-heading{
        color: $blue-500 !important;
    }

    .font {
        &-light {
            font-family: $font-family-Jakarta-light;
        }

        &-light-italic {
            font-family: $font-family-Jakarta-light-italic;
        }

        &-extra-light {
            font-family: font-family-Jakarta-extra-light;
        }

        &-extra-light-italic {
            font-family: $font-family-Jakarta-extra-light-italic;
        }


        &-regular {
            font-family: $font-family-Jakarta-regular;
        }

        &-italic {
            font-family: $font-family-Jakarta-italic;
        }

        &-semi-bold {
            font-family: $font-family-Jakarta-semi-bold;
        }

        &-semi-bold-italic {
            font-family: $font-family-Jakarta-semi-bold-italic;
        }

        &-bold {
            font-family: $font-family-Jakarta-bold;
        }

        &-bold-italic {
            font-family: $font-family-Jakarta-bold-italic;
        }

        &-black {
            font-family: $font-family-Jakarta-black;
        }

        &-black-italic {
            font-family: $font-family-Jakarta-black-italic;
        }

        // &-extra-bold {
        //     font-weight: 800;
        // }

        // &-black {
        //     font-weight: 900;
        // }
    }

    input.form-control, textarea.form-control{
        &::placeholder {
            font-family: $font-family-Jakarta-light;
        }
    }

    .btn{
        font-family: $font-family-Jakarta-semi-bold;
    }

    .story-card__title{
        font-family: $font-family-Jakarta-bold;
    }

    .chat-info .content{
        font-family: $font-family-Jakarta-semi-bold;
    }

    .group-contacts__contact{
        font-family: $font-family-Jakarta-regular;
    }

    .industries-modal .content{
        font-family: $font-family-Jakarta-regular !important;
    }

    .member .content, .members-filter .content{
        font-family: $font-family-Jakarta-semi-bold;
    }

    .notification-list .div-with-html body{
        font-family: $font-family-Jakarta-bold;
    }

    .story-help-form textarea::placeholder{
        font-family: $font-family-Jakarta-regular;
    }

    .tabs .nav-item.nav-link{
        font-family: $font-family-Jakarta-semi-bold;
    }

    .login__title-text{
        font-family: $font-family-Jakarta-bold;
    }

    .notifications .div-with-html body{
        font-family: $font-family-Jakarta-bold;
    }
}


.oubc {
    .btn{   
            &-secondary {
                    border-color: $rowing-blue-500 !important;
                    background-color: $neutral-0 !important;
                    color: $rowing-blue-500 !important;
            
                    &:hover {
                        border-color: $rowing-blue-600 !important;
                        background-color: $rowing-blue-50 !important;
                        color: $rowing-blue-600 !important;
                    }
            
                    &:focus {
                        box-shadow: none !important;
                        outline: 3px solid $rowing-blue-100 !important;
                        border-color: $rowing-blue-100 !important;
                    }
            
                    &:disabled {
                        opacity: 1 !important;
                        border-color: $rowing-blue-200 !important;
                        background-color: $neutral-0 !important;
                        color: $rowing-blue-200 !important;
                    }
            }
            &-secondary-form {
                border-color: $neutral-100 !important;
                background-color: $neutral-50 !important;
                color: $rowing-blue-500 !important;
        
                &:hover {
                    border-color: $rowing-blue-600 !important;
                    background-color: $rowing-blue-50 !important;
                    color: $rowing-blue-600 !important;
                }
        
                &:focus {
                    box-shadow: none !important;
                    outline: 3px solid $rowing-blue-100 !important;
                    border-color: $rowing-blue-100 !important;
                }
        
                &:disabled {
                    opacity: 1 !important;
                    border-color: $rowing-blue-200 !important;
                    background-color: $neutral-0 !important;
                    color: $rowing-blue-200 !important;
                }
            }
            &-primary:not(.secondary-form) {
                border-color: $rowing-blue-500 !important;
                    background-color: $rowing-blue-500 !important;
                    color: $neutral-0 !important;
            
                    &:hover {
                        border-color: $rowing-blue-600 !important;
                        background-color: $rowing-blue-600 !important;
                    }
            
                    &:focus {
                        box-shadow: none !important;
                    }
            
                    &:disabled {
                        opacity: 1 !important;
                        border-color: $rowing-blue-100 !important;
                        background-color: $rowing-blue-100 !important;
                        color: $rowing-blue-50 !important;
                    }
            }
        
            &-tertiary,
            &-link {
                    height: auto !important;
                    min-height: 0 !important;
            
                    border-color: transparent;
                    background-color: transparent;
                    color: $rowing-blue-500 !important;
            
                    &:hover {
                        color: $rowing-blue-600 !important;
                    }
            
                    &:focus {
                        box-shadow: none !important;
                    }
            
                    &:disabled {
                        opacity: 1 !important;
                        color: $rowing-blue-300 !important;
                    }
            }

            &-icon-only{
                border-color: $rowing-blue-500 !important;
                color: $rowing-blue-500 !important;

                svg{
                    fill: $rowing-blue-500 !important;
                }
            }
    }
    
    input.form-control{
            &:active:not(:disabled),
            &:focus:not(:disabled) {
                border-color: $rowing-blue-500 !important;
            }
    }
    textarea.form-control{
        &:active:not(:disabled),
        &:focus:not(:disabled) {
            border-color: $rowing-blue-500 !important;
        }
}

    .choice{
        .checked:not(.disabled){
            background-color: $rowing-blue-500 !important;
            border-color: $rowing-blue-500 !important;
        }

        .choice__wrapper.choice__radio.checked.disabled{
            background-color: $neutral-0 !important;
            border-color: $rowing-blue-500 !important;

            .choice-checked{
                background-color: $rowing-blue-500 !important;
            }
        }
    }

    .collapsible-sidebar__content-menu__active{
        .collapsible-sidebar__menu-label{
            color: $rowing-blue-500 !important;
        }
    }

    .page-heading{
        color: $rowing-blue-500 !important;
    }

    .background-wrapper, .registration-panel, .initial-route, .login{
        background-image: url(../assets/backgrounds/oubc-bg.svg) !important;
    }

    .tagline {
        font-family: $font-family-NotoSerif-semi-bold;
    }

    .header-banner{
        background-color: $rowing-blue-500 !important;
        background-image: none;
    }

    .avatar{
        background-color: $rowing-blue-500 !important;
    }
}

.public-event-browser-popup.oubc{
    .public-event-browser-popup__thumbnail{
        background-color: $rowing-blue-500 !important;
    }
}